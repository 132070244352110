import React, { useEffect, useState } from "react"
import LayoutComponent from "../../components/documentation-layout/Layout"

const About = () => {
  const [type, setType] = useState(null)
  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    setType(searchParam.get("type"));
  }, []);


  return (
    <LayoutComponent>
      <h1>GAINSS Documentation</h1>
      <p>
        This documentation provides a thorough guide to help you understand,
        {type === "tool" ? "" : "install,"} configure, and make the most of
        GAINSS.
      </p>

      <h2>Introduction</h2>
      <p>
        GAINSS is a Jira test management tool equipped with essential features
        for test planning and execution. It offers a wide range of powerful
        features and functionalities designed to help users enhance and automate
        their testing processes.
      </p>
      <p>
        Whether you are a developer, system administrator, or end user, this
        documentation will walk you through every aspect of GAINSS.
      </p>
      <p>
        Manage test cases, test cycles, and executions. Track progress, execute
        detailed steps, integrate with Jira issues, and automate workflows.
        Customizable and scalable for all testing needs.
      </p>
      <h2>Key Features</h2>
      <ul className="list-unstyled">
        <li>
          <strong>Test case management:</strong> Optimize the organization of
          your test cases, efficiently manage your testing assets, and reuse
          test cases across multiple sprints.
        </li>
        <li>
          <strong>Test metrics:</strong> Gain deeper insights into your test
          execution results with various report types, dashboards, and widgets.
        </li>
      </ul>
      {type === "tool" ? null : (
        <>
          <h2>Get Started</h2>
          <p>
            This section guides you through the initial steps to install and set
            up GAINSS. It includes system requirements, installation
            instructions, and any necessary dependencies or prerequisites to
            ensure a smooth deployment. Follow the instructions, and be up and
            running with GAINSS quickly.
          </p>

          <p>
            <a href="/documentation/getting-started/installation">
              Visit here to find out more.
            </a>
          </p>
        </>
      )}
      <hr />
      <p>
        This documentation is continuously updated to ensure it remains a
        comprehensive and valuable resource for users at all levels. Whether you
        are a beginner or an expert, we are confident that this documentation
        empowers you to make the most of GAINSS and achieve your desired
        outcomes.
      </p>
      <p>
        We appreciate your trust in our software and look forward to assisting
        you on your journey to success.
      </p>

      <p>
        <strong>GTEN Technologies Team</strong>
      </p>
    </LayoutComponent>
  )
}

export default About
